import * as React from "react";

export function addState<T, K>(initialState: T, fn: (state: T, setState: (newState: T) => void) => K) {
	return function <N, M = Omit<N, keyof K>>(BaseComponent: React.ComponentType<N>) {
		const C: React.FC<M> = (props) => {
			const [state, setState] = React.useState(initialState);
			// @ts-ignore
			return <BaseComponent {...props} {...fn(state, setState)} />;
		};

		return C;
	};
}

export type WithOptionalKeys<OriginalType, KeysToBeOptional> = Partial<KeysToBeOptional> &
	Omit<OriginalType, keyof KeysToBeOptional>;

/** This function will create a new component with props that already have default value filled in  */
export function injectProps<K, C>(Component: React.ComponentType<C>, props: K) {
	return ((additionalProps: WithOptionalKeys<C, K>) => (
		// @ts-ignore
		<Component {...props} {...additionalProps} />
	)) as React.FC<WithOptionalKeys<C, K>>;
}

export function makeComponentWithPropsTranslation<U, T>(
	Component: React.ComponentType<T>,
	useHook: (prevProps: U) => T,
): React.FC<U> {
	return (props: U) => {
		const newProps = useHook(props);
		return <Component {...newProps} />;
	};
}
