import moment from "moment";
import {AppConfig} from "app/AppConfig";
import {TIME, TIME_UNITS} from "constants/time";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {getNumberOfParticipantsStillInTheActiveCall} from "services/chat/video/VideoCallHelpers";

/**
 * Returns true if every other call participants have left the call.
 */
export function doAllOtherParticipantsHaveLeft(activeCall: ActiveCall) {
	const noActiveCall = !activeCall || !activeCall.participantMap;

	if (noActiveCall) {
		return true;
	}

	const nrOfParticipantsStillInCall = getNumberOfParticipantsStillInTheActiveCall(activeCall);

	// Returns true if only local user still in the call.
	// The value must be equal to one as we didn't filter out the local user.
	return nrOfParticipantsStillInCall === 1;
}

/**
 * Returns true if given `userId` call had timedout
 *
 * @param activeCall
 * @param userId
 * @returns
 */
export function isCallTimedOut(activeCall: ActiveCall, userId: number) {
	const participantData = activeCall.participantMap[userId];
	const initiatedDurationSeconds = (Date.now() - participantData.initiatedTime) / TIME.MILLISECONDS_IN_SECOND;
	return initiatedDurationSeconds >= AppConfig.CALL_TIMEOUT_SECONDS;
}

/**
 * Get Twilio access token valid until (expired)
 */
export function getTokenValidUntil(): Date {
	// Set to next 6 hour
	return moment().add(6, TIME_UNITS.HOURS).toDate();
}
