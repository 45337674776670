import {MouseEvents} from "../ts/constants/MouseEvents";
import {KeyboardEvents} from "../ts/constants/KeyboardEvents";

/**
 * List of events to indicate whether user has interacted with the browser
 */
const USER_INTERACTION_EVENTS: string[] = [
	MouseEvents.MOUSE_DOWN,
	MouseEvents.AUX_CLICK,
	MouseEvents.CONTEXT_MENU,
	KeyboardEvents.KEY_DOWN,
];

/**
 * Check whether the user has interacted with the browser
 */
export default function subscribeToUserInteraction(callback: () => void) {
	USER_INTERACTION_EVENTS.forEach((event) => {
		document.addEventListener(event, callback);
	});
	return () => {
		USER_INTERACTION_EVENTS.forEach((event) => {
			document.removeEventListener(event, callback);
		});
	};
}
