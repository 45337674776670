import {AppState} from "../redux/AppState";

import {AvailabilityStatus, PresenceAvailability, UserData} from "@sense-os/goalie-js/dist";
import {PureUserUtils} from "./PureUserUtils";

/**
 * A collection of user data utils
 * FIXME: Either 1. make all the methods here pure and remove `PureUserUtils
 * FIXME:     Or 2. move the pure methods from here to `PureUserUtils`.
 * FIXME:
 * FIXME: The first option is preferred.
 */
export class UserUtils {
	/**
	 * Get full name or short name
	 * If short = true then it will try to returns full name.
	 * if not then it will returns either firstname, lastName, or email
	 *
	 * @param {string} firstName user's first name
	 * @param {string} lastName user's last name
	 * @param {string} email user's email
	 * @param {boolean} short if true it will returns short name only
	 */
	public static getName(firstName: string, lastName: string, email: string, short?: boolean): string {
		if (!short && firstName && lastName) {
			return `${firstName} ${lastName}`;
		}
		return firstName || lastName || email;
	}

	/**
	 * Tries to make a screen name of a fixed length out of the given components.
	 * @param firstName
	 * @param lastName
	 * @param email
	 * @param maxLength the maximum desired length of the output. 1 extra character may be added, hence keep in mind the length of the returned value may be `maxLength+1`
	 */

	public static getShortenedName(firstName: string, lastName: string, email: string, maxLength: number): string {
		let result: string = "N/A";

		if (firstName && lastName) {
			result = firstName + " " + lastName;
			if (result.length > maxLength) {
				result = firstName[0] + ". " + lastName;
			}
		}

		if (email && (!firstName || !lastName)) {
			result = ((firstName && firstName[0]) || (lastName && lastName[0])) + ". " + email;
		}

		if (!firstName && !lastName && email) {
			result = email;
		}

		if (result.length > maxLength) {
			result = result.substr(0, maxLength) + "…"; // + "..."
		}

		return result;
	}

	/**
	 * Get full name or short name from user profile
	 * If short = true then it will try to returns full name.
	 * if not then it will returns either firstname, lastName
	 *
	 * @param {UserData} profile user's first name
	 */
	public static getNameFromUserProfile(userProfile: UserData, short?: boolean): string {
		if (!userProfile) {
			return null;
		}
		const {firstName, lastName} = userProfile;
		if (!short && firstName && lastName) {
			return `${firstName} ${lastName}`;
		}
		return firstName || lastName;
	}

	/**
	 * Returns chat presence availability of a given user. If there's no information on the given user available,
	 * `PresenceAvailability.Unavailable` is returned.
	 * @param state
	 * @param clientId
	 */
	public static userChatAvailability(state: AppState, clientId: number): PresenceAvailability {
		const clientAvailability: AvailabilityStatus = state.onlineUsers[clientId];
		if (!clientAvailability) {
			return PresenceAvailability.Unavailable;
		}
		return clientAvailability.availability;
	}

	/**
	 * @deprecated Use PureUserUtils.initialsToNumbers() instead!
	 */
	public static initialsToNumbers(fullName: string): number {
		return PureUserUtils.initialsToNumbers(fullName);
	}
}
