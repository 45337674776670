/**
 * Outgoing call types to be shown in call window
 */
export enum OutgoingCallTypes {
	INTAKE = "intake",
	SESSION = "session",
	EMDR = "emdr",
	COLLEAGUE = "colleague",
	MDO = "mdo",
	NONE = "none",
}

/**
 * Twilio media region server location
 * See https://www.twilio.com/docs/video/tutorials/video-regions-and-global-low-latency#twilio-regions
 */
export enum MediaRegion {
	GLL = "gll", // Global Low Latency
	DE = "de1", // Germany
}
