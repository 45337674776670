/**
 * Loading state enum
 * use this to indicate loading/fetching state on redux
 */
export enum LoadingState {
	/** No data has been fetched from the network yet */
	EMPTY = "empty",
	/** data is loaded (either its empty or not) */
	LOADED = "loaded",
	/** app is fetching data or there is a network request regarding the state */
	LOADING = "loading",
	/** network request error */
	ERROR = "error",
}
