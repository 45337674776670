import subscribeToUserInteraction from "./subscribeToUserInteraction";

export interface AudioPlayerOptions {
	/**
	 * Plays the audio directly after the audioPlayer instance is created
	 */
	autoplay: boolean;
	/**
	 * Loop the audio
	 */
	loop: boolean;
	/**
	 * Volume of the audio
	 */
	volume: number;
}

const defaultOptions: Partial<AudioPlayerOptions> = {autoplay: false, loop: true, volume: 0.3};

/**
 * Creates HTML audio instance.
 * Also applies autoplay policy from chrome https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
 * which only allows portal to play an audio only if the user has interacted with the portal tab.
 */
export default function createAudioPlayer(fileUrl: string, options?: Partial<AudioPlayerOptions>) {
	const audioOptions = {...defaultOptions, ...options};

	// True if the audio is meant to be played after the user interacted with the browser
	let hasQueuedAudio: boolean = false;

	let audio = new Audio(fileUrl);
	audio.loop = audioOptions.loop;
	audio.volume = audioOptions.volume;

	const playAudio = () => {
		if (isPlaying()) {
			return;
		}
		// Try to play audio. If fails, it's most likely that the user
		// hasn't interact with the portal. Therefore we need to queued the audio
		// so that when the user interacts, we can play the audio again.
		audio
			.play()
			.then(() => {
				hasQueuedAudio = false;
			})
			.catch(() => {
				// Only queue the audio if the audio is expected to be looping.
				if (audioOptions.loop) {
					hasQueuedAudio = true;
					return;
				}
			});
	};

	const isPlaying = () => !audio.paused;

	const pauseAudio = () => {
		audio.pause();
		hasQueuedAudio = false;
	};

	const resetTime = () => {
		audio.currentTime = 0;
	};

	if (audioOptions.autoplay) {
		playAudio();
	}

	const unsubscribeToUserInteraction = subscribeToUserInteraction(() => {
		if (hasQueuedAudio) {
			playAudio();
		}
	});

	return {
		play: playAudio,
		isPlaying,
		pause: pauseAudio,
		resetTime,
		clear: () => {
			resetTime();
			pauseAudio();
			unsubscribeToUserInteraction();
		},
	};
}
