import {ActiveCall} from "./ActiveCall";

/**
 * Returns userIDs of joined participants in active call.
 * Returns participants that are:
 * - Has `joinedTime` but no `leaveTime` (Already in the call and not leave yet)
 *
 * Doesn't include local user!
 *
 * @param {ActiveCall} activeCall
 * @param {number} localUserId
 */
export function getJoinedParticipants(activeCall: ActiveCall, localUserId?: number): number[] {
	if (!activeCall || !activeCall.participantMap) {
		return [];
	}
	const joinedParticipants: number[] = Object.keys(activeCall.participantMap)
		.filter((id) => {
			const participantHasJoined =
				!activeCall.participantMap[id].leaveTime && !!activeCall.participantMap[id].joinedTime;

			if (localUserId) {
				return id !== localUserId.toString() && participantHasJoined;
			}

			return participantHasJoined;
		})
		.map(Number);

	return joinedParticipants;
}

/**
 * Returns number of joined participants in active call.
 *
 * Doesn't include local user!
 *
 * @param {ActiveCall} activeCall
 * @param {number} localUserId
 */
export function getNumberOfJoinedParticipants(activeCall: ActiveCall, localUserId?: number): number {
	if (!activeCall || !activeCall.participantMap) {
		return 0;
	}
	return getJoinedParticipants(activeCall, localUserId).length;
}

/**
 * Returns participants that are:
 * - Has `initiatedTime` but no `joinedTime` and `leaveTime` (Still initated but not joined)
 * - Has `joinedTime` but no `leaveTime` (Already in the call and not leave yet)
 *
 * Doesn't include local user!
 *
 * @param {ActiveCall} activeCall
 * @param {number} localUserId
 */
export function getParticipantsStillInTheCall(activeCall: ActiveCall, localUserId?: number): number[] {
	if (!activeCall || !activeCall.participantMap) {
		return [];
	}

	return Object.keys(activeCall.participantMap)
		.filter((id) => {
			const participantHasNotLeft = !activeCall.participantMap[id].leaveTime;

			if (localUserId) {
				return id !== localUserId.toString() && participantHasNotLeft;
			}

			return participantHasNotLeft;
		})
		.map(Number);
}

/**
 * Returns number of participants that are still in active call
 *
 * Doesn't include local user!
 *
 * @param {ActiveCall} activeCall
 * @param {number} localUserId
 */
export function getNumberOfParticipantsStillInTheActiveCall(activeCall: ActiveCall, localUserId?: number): number {
	if (!activeCall || !activeCall.participantMap) {
		return 0;
	}
	const numberOfActiveParticipants: number = getParticipantsStillInTheCall(activeCall, localUserId).length;

	return numberOfActiveParticipants;
}

const MAX_TWILIO_PARTICIPANTS: number = 4;
// TODO: This is commented temporarily until the UI is changed
// const MAX_TWILIO_GROUP_PARTICIPANTS: number = 50;
const MAX_TWILIO_GROUP_PARTICIPANTS: number = 11;
/**
 * Returns true if portal is able to invite more participants into the call
 */
export function canInviteMoreParticipants(activeCall: ActiveCall): boolean {
	const numberOfParticipantsStillInTheActiveCall = getNumberOfParticipantsStillInTheActiveCall(activeCall);

	const hasReachedMaxParticipants: boolean =
		!activeCall?.isConferenceCall && numberOfParticipantsStillInTheActiveCall >= MAX_TWILIO_PARTICIPANTS;
	const hasReachedMaxParticipantsGroupCall: boolean =
		activeCall?.isConferenceCall && numberOfParticipantsStillInTheActiveCall >= MAX_TWILIO_GROUP_PARTICIPANTS;

	return !hasReachedMaxParticipants && !hasReachedMaxParticipantsGroupCall;
}
