/**
 * Author: leo Date: 13/05/2018
 */

/** Specifies INCOMING or OUTGOING call direction, or the absence of direction (NO_CALL)  */
export enum CallDirection {
	INCOMING = "in",
	OUTGOING = "out",
	NO_CALL = "none",
}
