import {EmdrSignal} from "@sense-os/goalie-js";
import {ActionType, createAction} from "typesafe-actions";

export const emdrActions = {
	// This action will be dispatched whenever portal get EMDR signal from remote user.
	signalIsReceived: createAction("emdr/SIGNAL_IS_RECEIVED", (signal: EmdrSignal) => ({signal}))(),

	// The video call window will use this action to trigger saga to send emdr signal.
	sendSignal: createAction("emdr/SEND_SIGNAL", (signal: EmdrSignal) => ({signal}))(),
	sendSignalWithThrottling: createAction("emdr/SEND_SIGNAL_WITH_THROTTLING", (signal: EmdrSignal) => ({signal}))(),

	// The following saga will be called by VideoCallService to start and stop EMDR saga.
	initEmdrSaga: createAction("emdr/INIT_EMDR_SAGA", (targetWindow: Window) => ({targetWindow}))(),
	cleanUpEmdrSaga: createAction("emdr/CLEAN_UP_EMDR_SAGA")(),
};

export type EmdrActions = ActionType<typeof emdrActions>;
