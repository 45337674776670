/** Returns true if x is undefined */
function isUndef<T>(x: T): boolean {
	return x === undefined;
}

/**
 * Shallowly compare 2 arrays
 */
export function equalArray<T>(arr1: T[], arr2: T[]): boolean {
	if (arr1 === arr2) {
		return true;
	}
	if (isUndef(arr1) || isUndef(arr2)) {
		return false;
	}
	if (arr1.length !== arr2.length) {
		return false;
	}
	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) {
			return false;
		}
	}

	return true;
}

/**
 * Shallow compare obj1 and obj2.
 * If 'keys' is provided, it will only compare the given keys
 */
export function equalObj<T>(obj1: T, obj2: T, keys?: string[], ignore?: string[]): boolean {
	if (obj1 === obj2) {
		return true;
	}
	if (!obj1 || !obj2) {
		return false;
	}

	if (!isUndef(keys)) {
		for (let i: number = 0; i < keys!.length; i++) {
			const k = keys![i];
			//@ts-ignore // FIXME!
			if (obj1[k] !== obj2[k]) {
				// console.log("different array val", obj1[k], obj2[k]);
				return false;
			}
		}
		return true;
	}

	let ks: string[];
	if (!keys) {
		const k1 = Object.keys(obj1);
		const k2 = Object.keys(obj2);
		if (!equalArray(k1, k2)) {
			// console.log("different keys");
			return false;
		}

		ks = k1;
		if (ignore) {
			ks = ks.filter((k) => ignore.indexOf(k) === -1);
		}
	} else {
		ks = keys;
	}

	for (let i = 0; i < ks.length; i++) {
		const k = ks[i];
		//@ts-ignore // FIXME!
		if (obj1[k] !== obj2[k]) {
			// console.log("value changed", k, obj1[k], obj2[k]);
			return false;
		}
	}

	return true;
}
