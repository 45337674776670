/**
 * Author: leo Date: 03/12/2018
 */

export enum MouseEvents {
	MOUSE_DOWN = "mousedown",
	MOUSE_ENTER = "mouseenter",
	MOUSE_LEAVE = "mouseleave",
	MOUSE_MOVE = "mousemove",
	MOUSE_OUT = "mouseout",
	MOUSE_OVER = "mouseover",
	MOUSE_UP = "mouseup",
	CONTEXT_MENU = "contextmenu",
	AUX_CLICK = "auxclick",
}
