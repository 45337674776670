/**
 * Solves insertion "0"s character as a suffix problem on a given number.
 * @example
 * // returns "01"
 * padNumWithZero(1, 2);
 * @example
 * // returns "001"
 * padNumWithZero(1, 3);
 * @example
 * // returns as it is when a given number greater or equal to the pad number.
 * // returns "10"
 * padNumWithZero(10, 2);
 * // returns "100"
 * padNumWithZero(100, 2);
 * @param x A number that we expect to be having "0"s character as a prefix.
 * @param maxPad A length of expected outcome of the concatenated number with "0"s character.
 * `maxPad` value will having no effect when it less than or equal with the length of stringify number of `x`.
 */
export function padNumWithZero(x: number, maxPad: number = 2) {
	let padNumber = maxPad;
	let y = x;
	while (y > 0) {
		y = Math.floor(y / 10);
		padNumber -= 1;
	}

	const zeroPad =
		padNumber <= 0
			? ""
			: `${Array.from(new Array(padNumber))
					.map((_) => "0")
					.join("")}`;

	return x > 0 ? `${zeroPad}${x}` : zeroPad;
}
