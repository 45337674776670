/**
 * Author: leo Date: 03/12/2018
 */

export enum SEMANTIC_COLORS {
	RED = "red",
	ORANGE = "orange",
	YELLOW = "yellow",
	OLIVE = "olive",
	GREEN = "green",
	TEAL = "teal",
	BLUE = "blue",
	VIOLET = "violet",
	PURPLE = "purple",
	PINK = "pink",
	BROWN = "brown",
	GREY = "grey",
	BLACK = "black",
}

export enum SEMANTIC_SIZES {
	MINI = "mini",
	TINY = "tiny",
	SMALL = "small",
	MEDIUM = "medium",
	LARGE = "large",
	BIG = "big",
	HUGE = "huge",
	MASSIVE = "massive",
}

/**
 * Valid `size` values for Semantic Modal
 */
export enum SEMANTIC_MODAL_SIZES {
	MINI = "mini",
	TINY = "tiny",
	SMALL = "small",
	LARGE = "large",
	FULL_SCREEN = "fullscreen",
}
