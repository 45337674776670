import * as React from "react";
import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";

import MuiButton, {ButtonProps} from "@material-ui/core/Button";
import {capitalize} from "@material-ui/core";
import classNames from "classnames";

export type ColorTypes = "primary" | "secondary" | "error" | "success" | "warning" | "default" | "inherit" | "info";

export type ColoredButtonProps = {color?: ColorTypes} & Omit<ButtonProps, "color">;

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		outlinedSuccess: {
			borderColor: theme.palette.success.main,
			color: theme.palette.success.main,
		},
		outlinedError: {
			borderColor: theme.palette.error.main,
			color: theme.palette.error.main,
		},
		outlinedWarning: {
			borderColor: theme.palette.warning.main,
			color: theme.palette.warning.main,
		},
		outlinedInfo: {
			borderColor: theme.palette.info.main,
			color: theme.palette.info.main,
		},
		containedSuccess: {
			backgroundColor: theme.palette.success.main,
			color: theme.palette.success.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.success.dark,
			},
		},
		containedError: {
			backgroundColor: theme.palette.error.main,
			color: theme.palette.error.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.error.dark,
			},
		},
		containedWarning: {
			backgroundColor: theme.palette.warning.main,
			color: theme.palette.warning.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.warning.dark,
			},
		},
		containedInfo: {
			backgroundColor: theme.palette.info.main,
			color: theme.palette.info.contrastText,
			"&:hover": {
				backgroundColor: theme.palette.info.dark,
			},
		},
		textSuccess: {
			color: theme.palette.success.main,
		},
		textError: {
			color: theme.palette.error.main,
		},
		textWarning: {
			color: theme.palette.warning.main,
		},
		textInfo: {
			color: theme.palette.info.main,
		},
	}),
);

export const validColors = ["default", "inherit", "primary", "secondary"] as const;

type ValidColor = typeof validColors[number];

export const Button: React.FC<ColoredButtonProps> = React.forwardRef(({children, color, ...props}, ref) => {
	const classes = useStyles();
	const variant = props.variant || "contained";
	const className = classes?.[`${variant}${capitalize(color || "primary")}`];
	const colorProp = validColors.includes(color as ValidColor) ? (color as ValidColor) : "primary";
	return (
		<MuiButton
			{...props}
			ref={ref}
			color={colorProp}
			variant={variant}
			className={classNames(className, props.className)}
		>
			{children}
		</MuiButton>
	);
});
