import {produce, Immutable} from "immer";

import {useCallback, useContext, useLayoutEffect, useMemo, useReducer, useRef, useState} from "react";

import * as React from "react";

export function createContext<State>(initialValue: State) {
	const Context =
		React.createContext<{
			subscribe: (cb: (...args: any[]) => any) => () => void;
			setState: React.Dispatch<React.SetStateAction<State>>;
			getState: () => State;
		}>(undefined);

	const Provider = ({children}) => {
		const [store, setState] = useState(initialValue);

		// Stash our store in a ref so it's current state can be referenced
		// in useMemo below without triggering an update
		const storeRef = useRef(store);
		storeRef.current = store;

		// Stash subscribers in a ref so they don't trigger updates
		const subscribersRef = useRef([]);

		useLayoutEffect(() => {
			// Notify all subscribers when store state changes
			subscribersRef.current.forEach((sub) => {
				try {
					sub();
				} catch (e) {
					// Ignore the error of individual callbacks.
				}
			});
		}, [store]);

		// Empty dep array means our context value will never change
		// so it will never trigger updates to useEffect/useCallback/useMemo
		const value = useMemo(
			() => ({
				setState,
				subscribe: (cb) => {
					subscribersRef.current.push(cb);
					return () => {
						subscribersRef.current = subscribersRef.current.filter((sub) => sub !== cb);
					};
				},
				getState: () => storeRef.current,
			}),
			[],
		);

		return <Context.Provider value={value}>{children}</Context.Provider>;
	};

	function useGetSelectorRef<U>(selector: (state: State) => U): React.MutableRefObject<U> {
		const [, forceRender] = useReducer((s) => s + 1, 0);
		const store = useContext(Context);

		// Store a ref of our current selector so it can be used
		// within checkForUpdates without triggering an update to the
		// callback itself
		const selectorRef = useRef(selector);
		selectorRef.current = selector;
		const selectedStateRef = useRef(selector(store.getState()));
		selectedStateRef.current = selector(store.getState());

		const checkForUpdates = useCallback(() => {
			// Compare new selected state to the last time this hook ran
			const newState = selectorRef.current(store.getState());
			// If new state differs from previous state, rerun this hook
			if (newState !== selectedStateRef.current) {
				forceRender();
			}
		}, [store]);

		// This effect should only run once on mount, since
		// store should never change
		useLayoutEffect(() => {
			// Subscribe to store changes, call checkForUpdates
			// when a change occurs
			return store.subscribe(checkForUpdates);
		}, [store, checkForUpdates]);

		return selectedStateRef;
	}

	function useContextSelectorRef<U>(selector: (state: State) => U): () => U {
		const ref = useGetSelectorRef(selector);
		const getter = React.useCallback(() => ref.current, [ref]);
		return getter;
	}

	function useContextSelector<U>(selector: (state: State) => U): U {
		const ref = useGetSelectorRef(selector);
		return ref.current;
	}

	function useContextSetState<U extends any[]>(fn: (prevState: State, ...args: U) => void) {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		const producedFn = useCallback(produce(fn), [produce, fn]);
		const {setState} = useContext(Context);

		const mutationFn = useCallback(
			(...args: U) => {
				setState((prevState) => producedFn(prevState as Immutable<State>, ...args) as State);
			},
			[producedFn, setState],
		);

		return mutationFn;
	}

	/**
	 * This function will create a hook that can mutate the value inside the context.
	 */
	function createSetStateHook<U extends any[]>(fn: (prevState: State, ...args: U) => void) {
		const producedFn = produce(fn);
		const useSetStateHook = () => {
			const {setState} = useContext(Context);
			const mutationFn = useCallback(
				(...args: U) => {
					setState((prevState) => producedFn(prevState as Immutable<State>, ...args) as State);
				},
				[setState],
			);

			return mutationFn;
		};

		return useSetStateHook;
	}

	/**
	 * This function will create a hook to access the value inside the context.
	 */
	function createGetStateHook<U>(selector: (state: State) => U) {
		const useGetStateHook = () => {
			const ref = useGetSelectorRef(selector);
			return ref.current;
		};

		return useGetStateHook;
	}

	/**
	 * This function will create a hook that return a getter that can access the value inside the context.
	 */
	function createStateGetterHook<U>(selector: (state: State) => U) {
		const useStateGetterHook = () => {
			const ref = useGetSelectorRef(selector);
			const getter = React.useCallback(() => ref.current, [ref]);
			return getter;
		};

		return useStateGetterHook;
	}

	return {
		Provider,
		Consumer: Context.Consumer,
		useContextSelector,
		useContextSelectorRef,
		useContextSetState,
		createSetStateHook,
		createGetStateHook,
		createStateGetterHook,
	};
}
