import {PrivateNote} from "@sense-os/goalie-js";
import {createAction, ActionType, createAsyncAction} from "typesafe-actions";
import {NoteState} from "../privateNotesTypes";

export const privateNotesActions = {
	// Get the note of one client of given hashId.
	// The value of privateNote in the payload might be undefined if there're
	// no note in the BE yet.
	fetchPrivateNote: createAsyncAction(
		"privateNotes/fetchPrivateNote",
		"privateNotes/fetchPrivateNoteSuccess",
		"privateNotes/fetchPrivateNoteError",
	)<{clientId: number; clientHashId: string}, {clientId: number; privateNote?: PrivateNote}, {clientId: number}>(),

	// Store the private notes to BE.
	createOrUpdatePrivateNote: createAsyncAction(
		"privateNotes/createOrUpdatePrivateNote",
		"privateNotes/createOrUpdatePrivateNoteSuccess",
		"privateNotes/createOrUpdatePrivateNoteError",
	)<{clientId: number; note: string}, {clientId: number; privateNote: PrivateNote}, {clientId: number}>(),

	// Next two actions is used to communicate the client's state to the video call window.
	inquiryClientNote: createAction("privateNotes/inquiryClientNote", (clientId: number, clientHashId) => ({
		clientId,
		clientHashId,
	}))(),
	broadcastClientNoteToCallWindow: createAction(
		"privateNotes/broadcastClientNoteToCallWindow",
		(clientId: number, note: NoteState) => ({clientId, note}),
	)(),

	// This one control the lifecycle of saga that listen to IWC communication
	// on the main tab.
	startListeningToIwc: createAction("privateNotes/startListeningToIwc", (targetWindow: Window) => ({targetWindow}))(),
	stopListeningToIwc: createAction("privateNotes/stopListeningToIwc")(),

	// These two actions are primarily used to regulate
	// the flag of whether portal need to block video window from closing
	// if the therapist still edits the private note at the end of a call.
	startEditingPrivateNoteInVideoCall: createAction("privateNotes/setEditingPrivateNoteInVideoCall")(),
	stopEditingPrivateNoteInVideoCall: createAction("privateNotes/stopEditingPrivateNoteInVideoCall")(),
};

export type PrivateNotesAction = ActionType<typeof privateNotesActions>;
